<template>
    <div>
        <header-portrate />

    <div class="pa-2 m-2">
        
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />
        <v-simple-table style="margin-top:25px;">
            <thead>
                <tr>
                    <th class="text-center">الحساب</th>
                    <th class="text-center">الاسم العربي</th>
                    <th class="text-center">الاسم الانجليزي</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in paytypesrows" :key="index">
                    <td>{{item.classid}}</td>
                    <td>{{item.namear}}</td>
                    <td>{{item.nameen}}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>

        <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default {
    components: { breadCrumbs, TabsComp, HeaderPortrate, Footer },
    data() {
        return {
            paytypesrows: [
                
            ],
        }
    },
    created() {
        this.getDefiniation();
    },
    methods: {
        getDefiniation(){
            const post = new FormData();
            post.append("type","getDefination")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.lang.langname);
            post.append("data[classid]",'all');
            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                // console.log(response.data);
                this.paytypesrows = response.data.results.data;
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            return [
                {
                    index:0,name:this.lang.chart_of_account,href:'/finance/coa',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.finance_jvs,href:'/finance/jvs',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:'أرصدة افتتاحية',href:'/finance/fient',class: 'mytab2',smclass:'font-small'
                },
                // {
                //     index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab2',smclass:'font-small'
                // },
                {
                    index:0,name:this.lang.paytypes,href:'/finance/paytypes',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.fixed_assets,href:'/finance/fixed-assets',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.definition,href:'/finance/definition',class: 'mytab',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.expenses_summary,href:'/expenses/summary',class: 'mytab4',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.general_expenses,href:'/expenses/general',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.exp_management,href:'/expenses/management',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.recurring_expensess,href:'/expenses/recurring',class: 'mytab3',smclass:'font-small'
                },
            ]
        },
        items: function() {
            return {
                    text: this.lang.definition,
                    disabled: true,
                    to: '/finance/definition',
                }
        },
       
    },
}
</script>